// ----------------------------------------------------------------------
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.
// ----------------------------------------------------------------------
var vi = {
    demo: {
        title: "Vietnamese",
        introduction: "Lorem Ipsum ch\u1EC9 \u0111\u01A1n gi\u1EA3n l\u00E0 v\u0103n b\u1EA3n gi\u1EA3 c\u1EE7a ng\u00E0nh in \u1EA5n v\u00E0 s\u1EAFp ch\u1EEF. Lorem Ipsum \u0111\u00E3 tr\u1EDF th\u00E0nh v\u0103n b\u1EA3n gi\u1EA3 ti\u00EAu chu\u1EA9n c\u1EE7a ng\u00E0nh k\u1EC3 t\u1EEB nh\u1EEFng n\u0103m 1500, khi m\u1ED9t nh\u00E0 in kh\u00F4ng x\u00E1c \u0111\u1ECBnh l\u1EA5y m\u1ED9t d\u00E3y lo\u1EA1i v\u00E0 x\u00E1o tr\u1ED9n n\u00F3 \u0111\u1EC3 t\u1EA1o th\u00E0nh m\u1ED9t cu\u1ED1n s\u00E1ch m\u1EABu. N\u00F3 \u0111\u00E3 t\u1ED3n t\u1EA1i kh\u00F4ng ch\u1EC9 n\u0103m th\u1EBF k\u1EF7, m\u00E0 c\u00F2n l\u00E0 b\u01B0\u1EDBc nh\u1EA3y v\u1ECDt trong l\u0129nh v\u1EF1c s\u1EAFp ch\u1EEF \u0111i\u1EC7n t\u1EED, v\u1EC1 c\u01A1 b\u1EA3n v\u1EABn kh\u00F4ng thay \u0111\u1ED5i. N\u00F3 \u0111\u01B0\u1EE3c ph\u1ED5 bi\u1EBFn v\u00E0o nh\u1EEFng n\u0103m 1960 v\u1EDBi vi\u1EC7c ph\u00E1t h\u00E0nh c\u00E1c t\u1EDD Letraset ch\u1EE9a c\u00E1c \u0111o\u1EA1n Lorem Ipsum, v\u00E0 g\u1EA7n \u0111\u00E2y h\u01A1n l\u00E0 v\u1EDBi ph\u1EA7n m\u1EC1m xu\u1EA5t b\u1EA3n tr\u00EAn m\u00E1y t\u00EDnh \u0111\u1EC3 b\u00E0n nh\u01B0 Aldus PageMaker bao g\u1ED3m c\u00E1c phi\u00EAn b\u1EA3n c\u1EE7a Lorem Ipsum.",
    },
    docs: {
        hi: "Ch\u00E0o",
        description: "C\u1EA7n gi\u00FAp \u0111\u1EE1\uFF1F\n Vui l\u00F2ng xem t\u00E0i li\u1EC7u c\u1EE7a ch\u00FAng t\u00F4i.",
        documentation: "t\u00E0i li\u1EC7u",
    },
    app: "\u1EE9ng d\u1EE5ng",
    user: "ng\u01B0\u1EDDi d\u00F9ng",
    list: "danh s\u00E1ch",
    edit: "ch\u1EC9nh s\u1EEDa",
    shop: "c\u1EEDa h\u00E0ng",
    blog: "blog",
    post: "b\u00E0i vi\u1EBFt",
    mail: "th\u01B0",
    chat: "tr\u00F2 chuy\u1EC7n",
    cards: "th\u1EBB",
    posts: "danh s\u00E1ch b\u00E0i vi\u1EBFt",
    create: "t\u1EA1o",
    kanban: "b\u1EA3ng",
    general: "chung",
    banking: "ng\u00E2n h\u00E0ng",
    booking: "\u0111\u1EB7t ph\u00F2ng",
    profile: "h\u1ED3 s\u01A1",
    account: "t\u1EA3i kho\u1EA3n",
    product: "s\u1EA3n ph\u1EA9m",
    invoice: "h\u00F3a \u0111\u01A1n",
    details: "chi ti\u1EBFt",
    checkout: "thanh to\u00E1n",
    calendar: "l\u1ECBch",
    analytics: "ph\u00E2n t\u00EDch",
    ecommerce: "th\u01B0\u01A1ng m\u1EA1i \u0111i\u1EC7n t\u1EED",
    management: "qu\u1EA3n l\u00FD",
    menu_level: "menu",
    menu_level_2a: "menu c\u1EA5p 2a",
    menu_level_2b: "menu c\u1EA5p 2b",
    menu_level_3a: "menu c\u1EA5p 3a",
    menu_level_3b: "menu c\u1EA5p 3b",
    menu_level_4a: "menu c\u1EA5p 4a",
    menu_level_4b: "menu c\u1EA5p 4b",
    item_disabled: "m\u1EE5c v\u00F4 hi\u1EC7u",
    item_label: "m\u1EE5c nh\u00E3n",
    item_caption: "m\u1EE5c ch\u00FA th\u00EDch",
    item_external_link: "m\u1EE5c li\u00EAn k\u1EBFt ngo\u00E0i",
    description: "m\u00F4 t\u1EA3",
    other_cases: "Tr\u01B0\u1EDDng h\u1EE3p kh\u00E1c",
    item_by_roles: "m\u1EE5c theo vai tr\u00F2",
    only_admin_can_see_this_item: "Ch\u1EC9 c\u00F3 qu\u1EA3n tr\u1ECB vi\u00EAn m\u1EDBi c\u00F3 th\u1EC3 nh\u00ECn th\u1EA5y m\u1EE5c n\u00E0y",
};
export default vi;
